<template>
	<div class="adsense-sm">
		<ins
			class="adsbygoogle"
			style="display:block"
			data-ad-client="ca-pub-8624981509801185"
			data-ad-slot="f08c47fec0942fa0"
			data-ad-format="auto"
		></ins>
	</div>
</template>
<script>
export default {
	mounted() {
		this.changeAdsense()
	},
	methods: {
		changeAdsense() {
			// eslint-disable-next-line no-undef
			;(adsbygoogle = window.adsbygoogle || []).push({})
		},
	},
}
</script>
